/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { useInterval, useWindowDimensions } from "~hooks";
import { getResponsiveImages, MEDIA_QUERIES } from "~utils/helpers";

import { Image, Go } from "~components";

import { ReactComponent as ExploreStickerStar } from "~assets/stickers/sticker-explore-star.svg";
import { ReactComponent as ExploreStickerText } from "~assets/stickers/sticker-explore-text.svg";

import { ReactComponent as FindUsSticker } from "~assets/stickers/sticker-find-us.svg";
import { ReactComponent as SocialsSticker } from "~assets/stickers/sticker-socials.svg";
import { ReactComponent as ScrollSticker } from "~assets/stickers/sticker-scroll.svg";
// ---------------------------------------------------------------------------
// globals

const fullAbsolute = `
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const desktopCSS = MEDIA_QUERIES.desktop;

/** --------------------------------------------------------------------------
 * @css
 */

const Container = styled.section`
  width: 100vw;
  aspect-ratio: 40/73;
  position: relative;
  z-index: 40;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #633510;

  ${desktopCSS} {
    aspect-ratio: initial;
    height: calc(100vh - 4rem);
  }
`;

const Images = styled.ul`
  ${fullAbsolute}

  .gatsby-image-wrapper {
    width: 100%;
    display: block;
  }
`;

const Figure = styled.figure`
  // use the easing/timing here to make it snazzy
  transition: 0.5s ease opacity;

  opacity: ${({ active }) => (active ? `1` : `0`)};

  ${fullAbsolute}
`;

const Content = styled.article`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10;

  ${desktopCSS} {
    //
  }
`;

// switching text

const Taglines = styled.ul`
  width: 100%;
  height: 25vw;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  justify-content: end;
  overflow: hidden;
  user-select: none;

  ${desktopCSS} {
    height: 20vw;
  }
`;

const Tagline = styled.li`
  transition: 0.3s ease transform;
  transform: translate3d(0, ${({ currentIndex }) => 25 * currentIndex}vw, 0);

  width: 100%;
  height: 100%;
  position: relative;
  flex: 1 0 auto;

  ${desktopCSS} {
    transform: translate3d(0, ${({ currentIndex }) => 20 * currentIndex}vw, 0);

    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

//

// 1 of 2 words either side
const Text = styled.h2`
  width: 100%;
  height: 100%;
  line-height: 80%;
  padding-bottom: 2.75%;
  text-transform: uppercase;
  text-align: ${({ align }) => align || `center`};
  color: ${({ color }) => color};

  ${desktopCSS} {
    // width: 100%;
    padding-bottom: 2.75%;
  }
`;

const Stickers = styled.div`
  width: 60%;
  height: calc(50% - 20vw);
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  ${desktopCSS} {
    width: 100%;
  }
`;

/** --------------------------------------------------------------------------
 * @component TODO: doc
 */
const FullPageCarousel = () => {
  const {
    sanityPage: {
      pagebuilder: { sections }
    }
  } = useStaticQuery(graphql`
    query FullPageCarousel {
      sanityPage(slug: { current: { eq: "/" } }) {
        pagebuilder {
          sections {
            ... on SanityFullPageCarousel {
              _key
              _type
              slides {
                _key

                image {
                  altText
                  asset {
                    gatsbyImageData(
                      width: 1920
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                  mobileImage {
                    asset {
                      gatsbyImageData(
                        width: 540
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                      )
                    }
                  }
                }
                textColor {
                  hex
                  title
                }
                topText
                bottomText
              }
            }
          }
        }
      }
    }
  `);

  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [activeTextIndex, setActiveTextIndex] = useState(0);

  const { windowSize } = useWindowDimensions();

  const [{ slides }] = sections.filter(
    (section) => section._type === `fullPageCarousel`
  );

  const { isDesktop } = useBreakpoint();

  //

  useInterval(() => {
    if (!slides?.[0]) {
      // return () => {};
      return;
    }

    setActiveImageIndex(
      activeImageIndex < slides.length - 1 ? activeImageIndex + 1 : 0
    );

    setActiveTextIndex(
      activeTextIndex < slides.length - 1 ? activeTextIndex + 1 : 0
    );

    /*
      [0] DO NADA
      [3] ...
      [2] ...
      [1] ...
      [0] DO NADA
     */

    // [0] to [0] snap happens at the peak of the idle of the last array index animation
    // only need to 'snap' once per total loop, and the activeImageIndex can be left alone entirely
    // activeTextIndex is the thing to change

    // we might want to keep something like this:

    // const timeout = setTimeout(() => {
    //   // once that has finished, we 'snap' the text to its next position
    //   setActiveTextIndex(
    //     activeTextIndex < slides.length - 1 ? activeTextIndex + 1 : 0
    //   );
    // }, ANIMATION_LENGTH);

    // return () => {
    //   clearTimeout(timeout);
    // };
  }, 3000);

  //

  return (
    <Container>
      <Images>
        {slides.map(({ image, _key }, imageIndex) => {
          const { desktopImage, mobileImage } = getResponsiveImages(image);

          let responsiveImage = <></>;

          // todo: make this modern
          if (typeof isDesktop !== `undefined`) {
            if (isDesktop) {
              responsiveImage = (
                <Image
                  css={css`
                    height: 100%;
                  `}
                  image={desktopImage}
                />
              );
            } else {
              responsiveImage = <Image image={mobileImage} />;
            }
          }

          return (
            <li key={_key}>
              <Figure active={activeImageIndex === imageIndex}>
                {responsiveImage}
              </Figure>
            </li>
          );
        })}
      </Images>

      {/* // */}

      <Content>
        {(isDesktop && (
          <div
            css={css`
              width: 100%;
              height: 100%;
              position: relative;
              overflow: hidden;
              display: flex;
              align-items: center;
              justify-content: center;
            `}
          >
            <Taglines>
              {slides.map(({ topText, bottomText, textColor, _key }) => (
                <Tagline key={`desktop-${_key}`} currentIndex={activeTextIndex}>
                  <Text className="a1" color={textColor.hex} align="left">
                    {topText}
                  </Text>

                  <Text
                    css={css`
                      ${desktopCSS} {
                        text-align: right;
                      }
                    `}
                    className="a1"
                    color={textColor.hex}
                    align="left"
                  >
                    {bottomText}
                  </Text>
                </Tagline>
              ))}
            </Taglines>
          </div>
        )) || (
          <>
            <div
              css={css`
                width: 100%;
                height: 50%;
                position: relative;
                overflow: hidden;
              `}
            >
              <Taglines>
                {slides.map(({ topText, textColor, _key }) => (
                  <Tagline
                    key={`mobile-${_key}`}
                    currentIndex={activeTextIndex}
                  >
                    <Text className="a1" color={textColor.hex} align="center">
                      {topText}
                    </Text>
                  </Tagline>
                ))}
              </Taglines>
            </div>

            <div
              css={css`
                width: 100%;
                height: 50%;
                position: relative;
                overflow: hidden;
              `}
            >
              <Taglines>
                {slides.map(({ bottomText, textColor, _key }) => (
                  <Tagline
                    key={`mobile-${_key}`}
                    currentIndex={activeTextIndex}
                  >
                    <Text className="a1" color={textColor.hex} align="center">
                      {bottomText}
                    </Text>
                  </Tagline>
                ))}
              </Taglines>
            </div>
          </>
        )}
      </Content>

      {/* // */}

      <Stickers>
        <div
          css={css`
            width: 100%;
            position: relative;
            flex: 1 1 auto;

            ${MEDIA_QUERIES.desktop} {
              width: 290px;
              aspect-ratio: 290/250;
              position: absolute;
              bottom: 1rem;
              right: 5rem;
            }

            ${MEDIA_QUERIES.wide} {
              width: 330px;
              bottom: 3rem;
            }
          `}
        >
          <div
            css={css`
              width: 55%;
              aspect-ratio: 1;
              position: absolute;
              top: 44%;
              left: 17%;
              transform: translate3d(-50%, -50%, 0);
            `}
          >
            <Go
              to="/products"
              css={css`
                transition: transform 0.3s var(--cubic-easing);

                :hover {
                  transform: scale(1.1);
                }
              `}
            >
              <div
                css={css`
                  width: 100%;
                  height: 100%;
                  position: relative;

                  transform: rotate(-10deg);
                `}
              >
                <ExploreStickerStar
                  css={css`
                    width: 100%;
                    height: 100%;
                    position: relative;

                    @keyframes spin {
                      0% {
                        transform: rotate(0deg);
                      }

                      100% {
                        transform: rotate(360deg);
                      }
                    }

                    animation: 10s linear infinite spin;
                  `}
                />

                <ExploreStickerText
                  css={css`
                    ${fullAbsolute}
                  `}
                />
              </div>
            </Go>
          </div>

          {/* // */}

          <div
            css={css`
              width: 66%;
              aspect-ratio: 2;
              position: absolute;
              top: 22%;
              left: 70%; // todo: tweak
              transform: translate3d(-50%, -50%, 0);
            `}
          >
            <Go
              to="/stockists"
              css={css`
                transition: transform 0.3s var(--cubic-easing);

                :hover {
                  transform: scale(1.1);
                }
              `}
            >
              <FindUsSticker
                css={css`
                  width: 100%;

                  @keyframes wiggle {
                    0% {
                      transform: rotate(0deg);
                    }
                    2.5% {
                      transform: rotate(-5deg);
                    }
                    5% {
                      transform: rotate(5deg);
                    }
                    7.5% {
                      transform: rotate(-5deg);
                    }

                    10% {
                      transform: rotate(5deg);
                    }

                    11.25% {
                      transform: rotate(0deg);
                    }
                  }

                  animation: 8s var(--cubic-easing) 3s infinite wiggle;
                `}
              />
            </Go>
          </div>

          {/* // */}

          <div
            css={css`
              width: 60%;
              aspect-ratio: 1;
              position: absolute;
              top: 78%; // todo: tweak
              left: 70%; // todo: tweak
              transform: translate3d(-50%, -50%, 0);
            `}
          >
            <Go
              to="https://www.instagram.com/denada_co/"
              css={css`
                transition: transform 0.3s var(--cubic-easing);

                :hover {
                  transform: scale(1.1);
                }
              `}
            >
              <SocialsSticker
                css={css`
                  width: 80%;

                  @keyframes hovering {
                    0% {
                      transform: scale(0.9);
                    }

                    25% {
                      transform: scale(1);
                    }

                    50% {
                      transform: scale(1.1);
                    }

                    75% {
                      transform: scale(1);
                    }

                    100% {
                      transform: scale(0.9);
                    }
                  }

                  animation: 8s linear infinite hovering;
                `}
              />
            </Go>
          </div>
        </div>

        <ScrollSticker
          css={css`
            width: 48px;
            position: relative;
            margin: 0 auto;
            opacity: 1;

            animation: float var(--animation-float);

            ${desktopCSS} {
              width: 68px;
              position: absolute;
              bottom: 1rem;
              right: 0;
              left: 0;
              cursor: pointer;
            }
          `}
          onClick={() => {
            if (windowSize.height) {
              window.scrollTo({ top: windowSize.height, behavior: `smooth` });
            }
          }}
        />
      </Stickers>
    </Container>
  );
};

export default FullPageCarousel;
